import React, {useEffect, useState} from "react";
import '../../static/article/article-editor.js';
import '../../static/article/css/article-editor.css';
import readOrWrite from './plugins/ReadOrWriteMode.js';
import {useCookies} from "react-cookie";
import {MAIN_PAGE_REQUEST} from "../../consts";
import './redactor.css'
import Button from "../Button";
import {Request} from "../../utils/Request";
import {setCurrentArticle} from "../../store/actions/chapterAction";


const Redactor = ({id, content, articleId, isReadOnly=false}) => {

    const [cookies, setCookie] = useCookies(['token', 'session_guid']);

    useEffect(() => {
        console.log('redactor', articleId, content)
        readOrWrite(isReadOnly)
        const app = window.ArticleEditor('#'+id, {
            css: '/frame/',
            content: !content ? '<p></p>' : content,
            plugins: ['readOrWrite'],
            toolbar: {
                hide: ['mobile', 'html']
            },
            control: false,
            image: {
                upload: MAIN_PAGE_REQUEST+'/file/article/'+articleId,
                multiple: false,
                url: false
            },
            autosave: {
                url: MAIN_PAGE_REQUEST+'/chapters/'+articleId+'/autosave',
                name: 'content',
                method: 'put'
            },
            grid: {
                gutter: '44px'
            },
            editor: {
                drop: true
            },
            draggable: true,
            subscribe: {
                'editor.change': function(event) {
                    console.log(event.params.html)
                },
                'editor.before.change': function(event) {
                    console.log(event)
                },
                'autosave.error': function(event) {
                    console.log(event)
                },
                'autosave.send': function(event) {
                    console.log(event)
                },
                'image.remove': function (event){
                    console.log(event);
                    //Кидать delete запрос по url, чтобы удалить неиспользованную картинку
                    Request.withAuth(cookies['token'], (token) => {
                        setCookie('token', token, {maxAge: 3598, path: '/'});
                    }, (axios) => {
                        return axios.delete(event.url);
                    }).then((res) => {
                        console.log(res.data);
                    }).catch((err) => {
                        console.log(err);
                    })
                },
                'autosave.before.send': async function(event) {
                    let xhr = event.get('xhr');
                    const token = cookies['token'];
                    if(token) {
                        xhr.setRequestHeader('Authorization', "bearer "+token);
                    }else{
                        try {
                            const data = await Request.refreshToken();
                            const token = data.data.session.success_token;
                            xhr.setRequestHeader('Authorization', "bearer "+token);
                            setCookie('token', token, {maxAge: 3598, path: '/'})
                        } catch (e){
                            console.log(e);
                        }
                    }
                },
                'upload.before.send': async function(event) {
                    let xhr = event.get('xhr');
                    const token = cookies['token'];
                    if(token) {
                        xhr.setRequestHeader('Authorization', "bearer "+token);
                    }else{
                        try {
                            const data = await Request.refreshToken();
                            const token = data.data.session.success_token;
                            xhr.setRequestHeader('Authorization', "bearer "+token);
                            setCookie('token', token, {maxAge: 3598, path: '/'})
                        } catch (e){
                            console.log(e);
                        }
                    }
                }
            }
        })

        return () => {
            app.destroy();
        }
    }, [articleId, content, isReadOnly]);

    const onChange = (e) => {
        console.log(e)
    }

    const formSend = (e) => {
        e.preventDefault();
        let formData = new FormData(e.nativeEvent.target);
        let obj = {};
        for (let [key, value] of formData) {
            obj[key] = value;
        }
        console.log(obj)
    }

    return(<div className={"bk-area-redactor"+(isReadOnly ? ' bk-area-redactor__read' : '')}>
        <form onSubmit={formSend}>
            <textarea id={id} name="content" onChange={onChange}/>
            <Button text={"Опубликовать"}/>
        </form>
    </div>)
};

export default Redactor;