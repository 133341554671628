import React, {useEffect, useState} from "react"
import Menu from "../components/Menu/menu";
import {MAIN_PAGE_REQUEST} from "../consts";
import './style.css';
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentChaptersMenu, setCurrentArticle, setCurrentChapters} from "../store/actions/chapterAction";
import IconSettings from "../components/Icons/Settings";
import IconTrash from "../components/Icons/Trash";
import IconInfo from "../components/Icons/Info";
import {useCookies} from "react-cookie";
import {Request} from "../utils/Request";
import {updateModal} from "../store/actions/appAction";

const MainPage = ({}) => {
    let { chapterId, articleId } = useParams()
    const [items, setItems] = useState([])
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const [cookies, setCookie] = useCookies(['token', 'session_guid'])
    const currentChaptersMenu = useSelector(state => state.chapter.currentChaptersMenu)

    const filterCurrentMenu = (data) => {
        if(!data.hasOwnProperty('chapters')){
            return
        }
        let itms = []
        itms.push({
            type: 'head'
        })
        if(data.parentId > 0) {
            itms.push({
                ...data.thisChapter,
                type: 'headline',
                action: () => {
                    if(data.returnId > 0) {
                        navigate('/chapter/'+data.returnId)
                    }else{
                        navigate('/')
                    }
                }
            })
        }else{
            itms.push({
                id: 1,
                firstName: data.user.firstName,
                secondName: data.user.secondName,
                type: 'user'
            })
        }
        dispatch(setCurrentChapters(data))
        data.chapters.filter(val => !val.isArticle).forEach((val) => {
            val.type = 'chapter'
            val.action = () => {
                navigate('/chapter/'+val.id)
            }
            val.moreActions = [
                {
                    key: 1,
                    name: 'Редактировать',
                    action: (e) => {
                        e.stopPropagation();
                        if(!chapterId) {
                            navigate('/update-chapter/'+val.id);
                        }else{
                            navigate('/chapter/'+chapterId+'/update-chapter/'+val.id);
                        }
                    }
                },
                {
                    key: 2,
                    name: 'Добавить раздел',
                    action: (e) => {
                        e.stopPropagation();
                        navigate('/chapter/'+val.id+'/new-chapter')
                    }
                },
                {
                    key: 3,
                    name: 'Добавить статью',
                    action: (e) => {
                        e.stopPropagation();
                        navigate('/chapter/'+val.id+'/new-article')
                    }
                },
                {
                    key: 4,
                    name: 'Удалить',
                    action: (e) => {
                        e.stopPropagation();
                        dispatch(updateModal({
                            show: true,
                            title: 'Удаление раздела',
                            type: 'Delete',
                            body: `Вы действительно хотите удалить раздел: "${val.name}" вместе с вложенными разделами и статьями?`,
                            anchor: 'Center',
                            params: {
                                id: parseInt(val.id),
                                chapterId: chapterId,
                                articleId: articleId
                            }
                        }));
                    }
                }
            ]
            itms.push(val);
        })
        data.chapters.filter(val => val.isArticle).forEach((val) => {
            val.type = 'article'
            if(!chapterId) {
                val.action = () => {
                    navigate('/article/' + val.id)
                }
            }else{
                val.action = () => {
                    navigate('/chapter/'+chapterId+'/article/' + val.id)
                }
            }
            val.moreActions = [
                {
                    key: 1,
                    name: 'Редактировать',
                    action: (e) => {
                        e.stopPropagation();
                        if(!chapterId) {
                            navigate('/article/' + val.id + '/redaction')
                        }else{
                            navigate('/chapter/'+chapterId+'/article/' + val.id + '/redaction')
                        }
                    }
                },
                {
                    key: 2,
                    name: 'Удалить',
                    action: (e) => {
                        e.stopPropagation();
                        dispatch(updateModal({
                            show: true,
                            title: 'Удаление статьи',
                            type: 'Delete',
                            body: `Вы действительно хотите удалить статью: "${val.name}"?`,
                            anchor: 'Center',
                            params: {
                                id: parseInt(val.id),
                                chapterId: chapterId,
                                articleId: articleId
                            }
                        }));
                    }
                }
            ]
            itms.push(val);
        })
        itms.push({
            type: 'add',
            text: 'Добавить статью',
            isBottomLine: true,
            action: () => {
                if(chapterId){
                    navigate('/chapter/'+chapterId+'/new-article')
                }else{
                    navigate('/new-article')
                }
            }
        })
        itms.push({
            type: 'add',
            text: 'Добавить раздел',
            isBottomLine: false,
            action: () => {
                if(chapterId){
                    navigate('/chapter/'+chapterId+'/new-chapter')
                }else{
                    navigate('/new-chapter')
                }
            }
        })
        itms.push({
            type: 'submenu',
            items: [
                {
                    icon: <IconSettings/>,
                    text: 'Настройки',
                    action: () => {

                    }
                },
                {
                    icon: <IconTrash/>,
                    text: 'Корзина',
                    action: () => {

                    }
                },
                {
                    icon: <IconInfo/>,
                    text: 'Помощь',
                    action: () => {

                    }
                }
            ]
        })
        console.log(itms);
        setItems(itms)
    }

    useEffect(() => {
        if(articleId){
            Request.withAuth(cookies['token'], (token) => {
                setCookie('token', token, {maxAge: 3598, path: '/'});
            }, (axios) => {
                return axios.get(MAIN_PAGE_REQUEST+'/chapters/'+articleId)
            }).then((res) => {
                console.log(res.data);
                dispatch(setCurrentArticle(res.data))
            }).catch((err) => {
                console.log(err);
            })
        }else{
            dispatch(setCurrentArticle({}))
        }
    }, [articleId])

    useEffect(() => {
        filterCurrentMenu(currentChaptersMenu);
    }, [currentChaptersMenu, articleId])

    useEffect(() => {
        console.log(chapterId);
        dispatch(getCurrentChaptersMenu(chapterId, cookies, setCookie));
    }, [chapterId])

    return(<div className="bk-area-content" style={{paddingBottom: 40}}>
        <Menu items={items}/>
        <Outlet/>
    </div>)
}

export default MainPage