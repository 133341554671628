import React from "react"
import {Outlet} from "react-router-dom";

const ChapterOutlet = ({}) => {

    return(<div className="bk-area-outlet">
        <Outlet/>
    </div>)
}

export default ChapterOutlet