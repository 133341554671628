export default (isRead) => {
    window.ArticleEditor.add('plugin', 'readOrWrite', {
        start: function () {
            if(isRead){
                console.log(isRead)
                this.app.popup.close();
                this.app.control.close();
                this.app.event.pause();
                this.app.observer.stop();
                this.app.path.disable();
                this.app.topbar.disable();
                this.app.toolbar.disable();
                this.app.toolbar.disableSticky();
                this.app.editor.getLayout().find('[contenteditable=true]').attr('contenteditable', false).addClass('arx-editable-pause');
                /*this.app.blocks.getBlocks().each((block) => {
                    console.log(block);
                    let $block = block.nodes[0];
                    $block.setAttribute('contenteditable', false);
                    //$block.classList.add('arx-editable-pause')
                })*/
            }
        }
    })
}