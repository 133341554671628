import React from "react"
import {Outlet} from "react-router-dom";
import {useSelector} from "react-redux";

const ArticleOutlet = ({}) => {
    const data = useSelector(state => state.chapter.currentChaptersState)

    return(<React.Fragment>
        <Outlet/>
    </React.Fragment>)
}

export default ArticleOutlet