import React, {useEffect, useRef, useState} from 'react'
import Text from "../Text";
import IconMore from "../../Icons/More";
import './index.css';
import ContextMenu from "../../ContextMenu";

const ChapterItem = ({item}) => {
    const [open, setOpen] = useState(false);
    const ref = React.useRef();

    const eventOut = (e) => {
        let target = e.target;
        if(Array.isArray(e.path) && e.path.indexOf(ref.current) === -1){
            setOpen(false)
        }else{
            //e.stopPropagation()
        }
    }

    const clickOpen = (e) => {
        e.nativeEvent.stopImmediatePropagation();
        console.log('click more')
        setOpen(!open);
    }

    useEffect(() => {
        document.addEventListener('click', eventOut, { capture: true })

        return () => {
            document.removeEventListener('click', eventOut, { capture: true })
        }
    }, [])

    return(<div className="bk-area-chapter-item" onClick={item.action}>
        <div className="bk-chapter-item__flex">
            <Text text={item.name}/>
            <div className="bk-chapter-item__more">
                <ContextMenu items={item.moreActions} isOpen={open} onOpen={(val) => setOpen(val)}
                             style={{top: 0, right: 24}} ref={ref}/>
                <div onClickCapture={clickOpen}><IconMore/></div>
            </div>
        </div>
    </div>)
}

export default ChapterItem