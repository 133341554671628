import React, {useEffect, useState} from "react"
import {useNavigate, useOutlet, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Redactor from "../components/Redactor/Redactor";
import TitleArea from "../components/TitleArea";
import IconLeft from "../components/Icons/Left";
import IconMore from "../components/Icons/More";
import IconRead from "../components/Icons/Read";
import IconEdit from "../components/Icons/Edit";
import {updateArticleTitle} from "../store/actions/chapterAction";
import {useCookies} from "react-cookie";

const ArticleGetOutlet = ({isRedaction=true}) => {
    let { chapterId, articleId } = useParams();
    const data = useSelector(state => state.chapter.currentArticle)
    const [name, setName] = useState(data?.chapter?.name);
    const [isOpen, setIsOpen] = useState(false);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['token', 'session_guid']);

    useEffect(() => {
        const timd = setTimeout(() => {
            console.log(name);
            dispatch(updateArticleTitle(name, articleId, chapterId, cookies, setCookie));
        }, 2000);

        return () => {
            clearTimeout(timd);
        }
    }, [name])

    useEffect(() => {
        setName(data?.chapter?.name);
    }, [data]);

    const onToggle = () => {
        let path = '/redaction';
        if(!isRedaction){
            path = '';
        }
        if(chapterId){
            navigate('/chapter/'+chapterId+'/article/'+data?.chapter?.id+path);
        }else{
            navigate('/article/'+data?.chapter?.id+path);
        }
    }

    return(<div className="bk-area-chapter-outlet">
        <div className="bk-chapter-outlet__header">
            <div className="bk-chapter-header__flex">
                <div className="bk-chapter-header__icons">
                    <div className="bk-chapter-header__icon-left"><IconLeft/></div>
                    <div className="bk-chapter-header__icon-right"><IconLeft/></div>
                </div>
                <div className="bk-chapter-header__name">
                    {name}
                </div>
                <div className="bk-chapter-header__console">
                    <div className="bk-chapter-header__editmode" onClick={onToggle}>
                        <div className="bk-chapter-header__icon">{isRedaction ? <IconRead/> : <IconEdit/>}</div>
                        <div className="bk-chapter-header__editmode-title">{isRedaction ? 'Режим чтения' : 'Режим записи'}</div>
                    </div>
                    <div className="bk-chapter-header__icon bk-chapter-header__icon-more"><IconMore/></div>
                </div>
            </div>
        </div>
        <div className="bk-area-middle">
            <TitleArea val={name} name={"title"} onChanged={(e) => setName(e.target.value)} disabled={isRedaction}/>
            <Redactor id="editor" content={data?.article?.content} articleId={data?.chapter?.id} isReadOnly={isRedaction}/>
        </div>
    </div>)
}

export default ArticleGetOutlet