import React from "react"
import IconLeft from "../../Icons/Left";
import Text from "../Text";
import IconSearch from "../../Icons/Search";
import './index.css'

const HeadReturnItem = ({item}) => {

    return(<div className="bk-area-head-return-item">
        <div className="bk-head-return-item__flex">
            <div className="bk-head-return-item__left" onClick={item.action}><IconLeft/></div>
            <Text text={item.name}/>
            <div className="bk-head-return-item__search"><IconSearch/></div>
        </div>
    </div>)
}

export default HeadReturnItem