import React from "react"
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import GridItems from "../components/GridItems";
import {MAIN_PAGE_REQUEST} from "../consts";

const ChapterGetOutlet = ({}) => {
    const {chapterId} = useParams();
    const data = useSelector(state => state.chapter.currentChaptersState)
    let navigate = useNavigate();

    const onClickItem = (item) => {
        if(item.isArticle){
            navigate('/article/'+item.id);
        }else{
            navigate('/chapter/'+item.id);
        }
    }

    const actionNavigate = (path) => {
        if(chapterId){
            navigate('/chapter/'+chapterId+path)
        }else{
            navigate(path)
        }
    }

    const getFilename = (val) => {
        if(val.files && val.files.length > 0){
            return MAIN_PAGE_REQUEST+'/file/'+val.files[0].filename;
        }else{
            return '';
        }
    }

    return(data.chapters ? <React.Fragment>
            {
                data.parentId > 0 ? <div className="bk-area-chapter-outlet__head"
                                         style={{backgroundImage: 'url(' + getFilename(data.thisChapter) + ')'}}>
                    <div className="bk-area-middle__flex">
                        <div>
                            <div/>
                            <div>
                                <div className="bk-chapter__title">{data.thisChapter.name}</div>
                                <div className="bk-chapter__desc">{data.thisChapter.description}</div>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            <div className="bk-area-middle">
                <GridItems name={"Разделы"}
                           placeholderEmpty={"Создай первый раздел"}
                           buttonName={"Добавить раздел"}
                           items={data.chapters.filter(val => !val.isArticle)}
                           onClickItem={onClickItem}
                           action={() => actionNavigate('/new-chapter')}/>
                <GridItems name={"Статьи"}
                           placeholderEmpty={"Напиши первую статью"}
                           buttonName={"Добавить статью"}
                           items={data.chapters.filter(val => val.isArticle)}
                           onClickItem={onClickItem}
                           action={() => actionNavigate('/new-article')}/>
            </div>
        </React.Fragment> : null)
}

export default ChapterGetOutlet