import React from "react"
import './index.css'
import Text from "../Text";

const SubMenu = ({items}) => {

    return(<div className="bk-area-submenu">
        {items.map((val, idx) => {
            return (<div className="bk-submenu__item" onClick={val.action} key={idx}>
                {val.icon}
                <Text text={val.text} style={{marginLeft: 8}}/>
            </div>)
        })}
    </div>)
}

export default SubMenu;
