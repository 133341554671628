import React from "react"
import IconProfile from "../../Icons/Profile";
import Text from "../Text";
import IconMore from "../../Icons/More";
import './index.css'

const ProfileItem = ({user}) => {
    return(<div className="bk-area-profile-item" onClick={user.action}>
        <div className="bk-profile-item__flex">
            <div className="bk-profile-item__photo">
                {user.photo ? <img src={user.photo} alt={""}/> : <IconProfile/>}
            </div>
            <Text text={user.firstName + " " + user.secondName}/>
            <IconMore onClick={user.actionMore}/>
        </div>
    </div>)
}

export default ProfileItem