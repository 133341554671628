import React from "react"
import IconSearch from "../../Icons/Search";
import './index.css'
import IconMenu from "../../Icons/Menu";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const HeadItem = ({}) => {

    const isTwoHead = useSelector(state => state.chapter.isTwoHead);
    let navigate = useNavigate();

    const onClick = () => {
        navigate('/');
    }

    return(<div className={"bk-area-head-item"+(isTwoHead ? ' bk-area-head-item__two' : '')}>
        <div className="bk-head-item__flex">
            <div className="bk-head-item__menu"><IconMenu/></div>
            <div className="bk-head-item_text-logo" onClick={onClick}>
                <span>База </span>
                Знаний
            </div>
            <IconSearch/>
        </div>
    </div>)
}

export default HeadItem