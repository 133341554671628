import React, {useState} from "react"
import {useNavigate, useParams} from "react-router-dom";
import TitleArea from "../components/TitleArea";
import Button from "../components/Button";
import {MAIN_PAGE_REQUEST} from "../consts";
import {Request} from "../utils/Request";
import {useCookies} from "react-cookie";
import {getCurrentChaptersMenu} from "../store/actions/chapterAction";
import {useDispatch} from "react-redux";

const ArticleNewOutlet = ({}) => {
    let { chapterId = 0 } = useParams();
    const [name, setName] = useState();
    const [cookies, setCookie] = useCookies(['token', 'session_guid']);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = () => {

        Request.withAuth(cookies['token'], (token) => {
            setCookie('token', token, {maxAge: 3598, path: '/'});
        }, (axios) => {
            return axios.post(MAIN_PAGE_REQUEST+'/chapters', {
                chapterId: chapterId,
                form: {
                    name: name,
                    isArticle: true
                },
                content: ''
            })
        }).then((res) => {
            let articleId = res.data.chapter.id;
            if(chapterId){
                navigate('/chapter/'+chapterId+'/article/'+articleId+'/redaction');
            }else{
                navigate('/article/'+articleId+'/redaction');
            }
            dispatch(getCurrentChaptersMenu(chapterId, cookies, setCookie));
        }).catch((er) => {
            console.log(er);
        })
    }

    return(<div className="bk-area-chapter-outlet">
        <div className="bk-area-middle">
            <TitleArea val={name} name={"title"} onChanged={(e) => setName(e.target.value)}/>
            <div style={{textAlign: 'left', marginTop: 15, padding: '0 24px'}}><Button text={"Создать"} action={onSubmit}/></div>
        </div>
    </div>)
}

export default ArticleNewOutlet