import React from "react"
import Button from "../Button";
import Text from "../Menu/Text";
import './index.css';
import {MAIN_PAGE_REQUEST} from "../../consts";

const GridItems = ({name, buttonName, placeholderEmpty, action, items, onClickItem}) => {

    const onClick = (item) => {
        if(onClickItem){
            onClickItem(item);
        }
    }

    const getFilePath = (val) => {
        if(val.files && val.files.length > 0) {
            return MAIN_PAGE_REQUEST + "/file/"+val.files[0].filename;
        }else{
            return '';
        }
    }

    return(<div className="bk-area-grid-items">
        <div className="bk-grid-items__flex">
            <div className="bk-text-middle">{name}</div>
            {items.length > 0 && <Button text={buttonName} action={action}/>}
        </div>
        <div className="bk-grid-items__content">
            { items.length > 0 &&
                items.map((val) => {
                    return(<div className="bk-grid-items__card" key={val.id} onClick={()=>onClick(val)}>
                        <div className="bk-grid-items__image"
                             style={{backgroundImage: 'url('+getFilePath(val)+')'}}/>
                        <Text text={val.name}/>
                    </div>)
                })
            }
        </div>
        { items.length === 0 &&  <div className="bk-grid-items__empty">
            <div className="bk-text-empty">{placeholderEmpty}</div>
            <Button text={buttonName} action={action}/>
        </div>}
    </div>)
}

export default GridItems