import {ACTION_UPDATE_MODAL, MAIN_PAGE_REQUEST} from "../../consts";
import {Request} from "../../utils/Request";
import {getCurrentChaptersMenu, setCurrentArticle, setCurrentChaptersMenu} from "./chapterAction";

export const updateModal = (data) =>{
    return{
        type: ACTION_UPDATE_MODAL,
        payload: data
    }
}

export const deleteChapterOrArticle = (id, currentChapter, isReturnMenu, cookies, setCookie) => {
    return (dispatch) => {
        const url = MAIN_PAGE_REQUEST+'/chapters/'+id;
        Request.withAuth(cookies['token'], (token) => {
            setCookie('token', token, {maxAge: 3598, path: '/'});
        }, (axios) => {
            return axios.delete(url)
        }).then((res) => {
            console.log(res.data);
            if(res.data.success) {
                if(isReturnMenu){
                    isReturnMenu();
                }
                dispatch(getCurrentChaptersMenu(currentChapter, cookies, setCookie));
                dispatch(updateModal({show: false}));
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}