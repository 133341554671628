import React from "react"
import './index.css'
import Text from "../Menu/Text";

const ContextMenu = React.forwardRef(({items, isOpen, style, onOpen}, ref) => {

    const onClick = (event, action) => {
        if(action){
            action(event);
        }
        if(onOpen){
            onOpen(false)
        }
    }

    return(<div className={"bk-area-context-menu"+(isOpen ? " bk-context-menu__open" : "")} style={style} ref={ref}>
        {
            items.map((val) => {
                return(<div className="bk-context-menu__item"
                            onClickCapture={(e) => onClick(e, val.action)}
                            key={val.key}>
                    <Text text={val.name}/>
                </div>)
            })
        }
    </div>)
})

export default ContextMenu