import React, {useEffect, useState} from "react"
import FileZone from "../components/FileZone";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import Button from "../components/Button";
import {useNavigate, useParams} from "react-router-dom";
import {Request} from "../utils/Request";
import {MAIN_PAGE_REQUEST} from "../consts";
import {useCookies} from "react-cookie";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentChapter} from "../store/actions/chapterAction";

const ChapterNewOutlet = ({isNew=true}) => {
    let { chapterId, chapterUp } = useParams();
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [file, setFile] = useState(null);
    const [preFile, setPreFile] = useState(null);
    const [cookies, setCookie] = useCookies(['token', 'session_guid']);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const chapter = useSelector(state => state.chapter.currentChaptersState);

    useEffect(() => {
        if(!isNew){
            dispatch(getCurrentChapter(chapterUp, cookies, setCookie));
        }else{
            setTitle('');
            setDesc('');
            setPreFile(null);
            setFile(null);
        }
    }, [isNew]);

    const getFilePath = (files) => {
        if(files && files.length > 0) {
            return MAIN_PAGE_REQUEST + "/file/"+files[0].filename;
        }else{
            return null;
        }
    }

    useEffect(() => {
        if(!isNew && chapter.hasOwnProperty('thisChapter')){
            setTitle(chapter.thisChapter.name);
            setDesc(chapter.thisChapter.description);
            setPreFile(getFilePath(chapter.thisChapter.files));
            setFile(null);
        }
    }, [chapter]);

    const onClick = () => {
        console.log(title, desc);
        if(!title && !desc){
            return;
        }
        Request.withAuth(cookies['token'], (token) => {
            setCookie('token', token, {maxAge: 3598, path: '/'});
        }, (axios) => {
            if(isNew) {
                return axios.post(MAIN_PAGE_REQUEST + '/chapters/', {
                    chapterId: chapterId,
                    form: {
                        isArticle: false,
                        name: title,
                        description: desc
                    }
                });
            }else{
                return axios.put(MAIN_PAGE_REQUEST + '/chapters/' + chapter.thisChapter.id, {
                    form: {
                        isArticle: false,
                        name: title,
                        description: desc
                    }
                });
            }
        }).then((res) => {
            console.log(res.data);
            if(file) {
                let data = new FormData();
                data.append('file[]', file);
                return Request.withAuth(cookies['token'], (token) => {
                    setCookie('token', token, {maxAge: 36000, path: '/'});
                }, (axios) => {
                    if(isNew) {
                        return axios.post(MAIN_PAGE_REQUEST + '/file/chapter/' + res.data.chapter.id, data, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });
                    }else{
                        return axios.post(MAIN_PAGE_REQUEST + '/file/chapter/' + chapter.thisChapter.id, data, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });
                    }
                })
            }else{
                navigate('/chapter/'+res.data.chapter.id);
            }
        }).catch((err) => {
            console.log(err);
        }).then((res) => {
            console.log(res);
            if(res.data.file){
                navigate('/chapter/'+res.data.chapterId);
            }
        })
    }

    return(<div className="bk-area-new-chapter">
        <div className="bk-new-chapter__title">Новый раздел</div>
        <div className="bk-new-chapter__content">
            <div className="bk-new-chapter__left">
                <Input name={"title"} type={'text'} value={title} setValue={(val) => setTitle(val)} placeholder={"Название"}/>
                <Textarea name={"description"} placeholder={"Описание"} style={{marginTop: 12}}
                          value={desc} setValue={(val) => setDesc(val)}/>
                <div style={{marginTop: 40, textAlign: 'left'}}>
                    <Button text={isNew ? "Создать" : "Обновить"} style={{padding: '16px 32px'}} action={onClick}/>
                </div>
            </div>
            <div className="bk-new-chapter__right">
                <FileZone onCurrentFile={(fl) => setFile(fl)} fileImg={!isNew ? preFile : null}/>
            </div>
        </div>
    </div>)
}

export default ChapterNewOutlet