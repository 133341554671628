import {
    ACTION_CURRENT_ARTICLES,
    ACTION_CURRENT_CHAPTERS,
    ACTION_IS_TWO_HEADER,
    ACTION_SET_CHAPTERS_MENU,
    MAIN_PAGE_REQUEST
} from "../../consts";
import {Request} from "../../utils/Request";
import React from "react";

export const setCurrentChapters = (data) => {
    return{
        type: ACTION_CURRENT_CHAPTERS,
        payload: data
    }
}

export const setTwoHeader = (isTwoHeader) => {
    return {
        type: ACTION_IS_TWO_HEADER,
        payload: isTwoHeader
    }
}

export const setCurrentArticle = (data) => {
    return {
        type: ACTION_CURRENT_ARTICLES,
        payload: data
    }
}

export const setCurrentChaptersMenu = (data) => {
    return {
        type: ACTION_SET_CHAPTERS_MENU,
        payload: data
    }
}

export const getCurrentChapter = (chapterId, cookies, setCookie) => {
    return dispatch => {
        let url = MAIN_PAGE_REQUEST+'/chapters';
        if(chapterId){
            url += '/'+chapterId;
        }
        Request.withAuth(cookies['token'], (token) => {
            setCookie('token', token, {maxAge: 3598, path: '/'});
        }, (axios) => {
            return axios.get(url)
        }).then((res) => {
            console.log(res.data);
            dispatch(setCurrentChapters(res.data));
        }).catch((err) => {
            console.log(err);
        })
    }
}

export const getCurrentChaptersMenu = (chapterId, cookies, setCookie) => {
    return dispatch => {
        let url = MAIN_PAGE_REQUEST+'/chapters';
        if(chapterId){
            url += '/'+chapterId;
        }
        Request.withAuth(cookies['token'], (token) => {
            setCookie('token', token, {maxAge: 3598, path: '/'});
        }, (axios) => {
            return axios.get(url)
        }).then((res) => {
            console.log(res.data);
            dispatch(setCurrentChaptersMenu(res.data));
        }).catch((err) => {
            console.log(err);
        })
    }
}

export const updateArticleTitle = (title, chapterId, parentChapterId, cookies, setCookie) => {
    return dispatch => {
        let url = MAIN_PAGE_REQUEST+'/chapters/'+chapterId+'/settings';
        Request.withAuth(cookies['token'], (token) => {
            setCookie('token', token, {maxAge: 3598, path: '/'});
        }, (axios) => {
            return axios.post(url, {
                title: title
            })
        }).then((res) => {
            console.log(res.data);
            if(res.data.success) {
                dispatch(getCurrentChaptersMenu(parentChapterId, cookies, setCookie));
            }
        }).catch((err) => {
            console.log(err);
        })
    }
}
