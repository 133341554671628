import axios from "axios";
import {MAIN_PAGE_AUTH} from "../consts";

export const Request = {
    withAuth: (token, callbackRefresh, callbackRequest) => {
        console.log('withAuth',token)
        console.log(document.cookie);
        axios.defaults.headers.common['Authorization'] = 'bearer ' + token;

        return callbackRequest(axios).catch((er) => {
            if(er.response && er.response.data){
                if(er.response.status === 401) {
                    return axios.get(MAIN_PAGE_AUTH + "/api/auth/refresh", {
                        withCredentials: true
                    })
                }else if(er.response.status === 500 && er.response.data.token_invalid){
                    return axios.get(MAIN_PAGE_AUTH + "/api/auth/refresh", {
                        withCredentials: true
                    })
                }else{
                    return Promise.reject(er);
                }
            }else{
                return Promise.reject(er);
            }
        }).then((res) => {
            if(res.config.url === MAIN_PAGE_AUTH+"/api/auth/refresh"){
                console.log('refresh');
                callbackRefresh(res.data.session.success_token);
                axios.defaults.headers.common['Authorization'] = 'bearer ' + res.data.session.success_token;
                return callbackRequest(axios);
            }else{
                return res;
            }
        })
    },
    refreshToken: () => {
        return axios.get(MAIN_PAGE_AUTH + "/api/auth/refresh", {
            withCredentials: true
        })
    }
}