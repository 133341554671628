import './App.css';
import React, {useEffect} from "react";
import MainPage from "./pages/MainPage";
import {
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import ChapterOutlet from "./pages/ChapterOutlet";
import ArticleOutlet from "./pages/ArticleOutlet";
import ChapterGetOutlet from "./pages/ChapterGetOutlet";
import ChapterNewOutlet from "./pages/ChapterNewOutlet";
import ArticleGetOutlet from "./pages/ArticleGetOutlet";
import {useCookies} from "react-cookie";
import axios from "axios";
import ArticleNewOutlet from "./pages/ArticleNewOutlet";
import {MAIN_PAGE_AUTH} from "./consts";
import Modal from "./components/Modal";

function App() {

    const [cookies, setCookie] = useCookies(['token', 'session_guid']);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const pars = Object.fromEntries(urlSearchParams.entries());
        console.log(cookies);
        console.log(pars);
        if(!cookies['session_guid']) {
            //window.location.href = MAIN_PAGE_AUTH+'/auth?appGuid=ce3301ea-e659-4ce0-889d-dfc28eed4c6b&path=/';
            window.location.href = MAIN_PAGE_AUTH+'/auth?appGuid=5ef54bee-d249-46c3-a732-01dd0eb49035&path=/';
        }else if(!cookies['token']){
            axios.get(MAIN_PAGE_AUTH+'/api/auth', {
                params: {
                    //appGuid: 'ce3301ea-e659-4ce0-889d-dfc28eed4c6b',
                    appGuid: '5ef54bee-d249-46c3-a732-01dd0eb49035',
                    returnPath: '/'
                },
                withCredentials: true
            }).then((res) => {
                console.log(res);
                if (res.data.success) {
                    const token = res.data.session.success_token;
                    setCookie('token', token, {maxAge: 3598, path: '/'});
                    console.log(token);
                }else{
                    setCookie('session_guid', null, {maxAge: -99999, path: '/'});
                    //window.location.href = MAIN_PAGE_AUTH+'/auth?appGuid=ce3301ea-e659-4ce0-889d-dfc28eed4c6b&path=/';
                    window.location.href = MAIN_PAGE_AUTH+'/auth?appGuid=5ef54bee-d249-46c3-a732-01dd0eb49035&path=/';
                }
            }).catch((err) => {
                console.log(err.response.data);
            })
        }
    }, [cookies]);

  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<MainPage/>}>
                <Route index element={<ChapterGetOutlet/>}/>
                <Route path="new-chapter" element={<ChapterNewOutlet/>}/>
                <Route path="new-article" element={<ArticleNewOutlet/>}/>
                <Route path="chapter" element={<ChapterOutlet/>}>
                    <Route path=":chapterId" element={<ChapterGetOutlet/>}/>
                    <Route path=":chapterId/new-chapter" element={<ChapterNewOutlet/>}/>
                    <Route path=":chapterId/new-article" element={<ArticleNewOutlet/>}/>
                    <Route path=":chapterId/update-chapter/:chapterUp" element={<ChapterNewOutlet isNew={false}/>}/>
                    <Route path=":chapterId/article/:articleId" element={<ArticleGetOutlet/>}/>
                    <Route path=":chapterId/article/:articleId/redaction" element={<ArticleGetOutlet isRedaction={false}/>}/>
                </Route>
                <Route path="article" element={<ArticleOutlet/>}>
                    <Route path=":articleId" element={<ArticleGetOutlet/>}/>
                    <Route path=":articleId/redaction" element={<ArticleGetOutlet isRedaction={false}/>}/>
                </Route>
                <Route path="update-chapter/:chapterUp" element={<ChapterNewOutlet isNew={false}/>}/>
            </Route>
            <Route
                path="*"
                element={<Navigate to="/" />}
            />
        </Routes>
        <Modal/>
    </div>
  );
}

export default App;
