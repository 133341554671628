import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import './index.css';
import Button from "../Button";

const FileZone = ({fileImg = null, onCurrentFile}) => {
    const [img, setImg] = useState(fileImg);
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log(acceptedFiles);
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        if(onCurrentFile){
            onCurrentFile(acceptedFiles[0])
        }
        reader.onload = () => {
            setImg(reader.result);
        }
        reader.onerror = error => console.log(error);
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    useEffect(() => {
        setImg(fileImg);
    }, [fileImg])

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} multiple={false} />
            <div className="bk-area-file">
                <div style={!!img ? {opacity: 0} : {}}>
                    <div className="bk-file__title">Форматы файла: PNG, JPG</div>
                    <div style={{margin: '20px 0'}}><Button text={'Загрузить превью'}/></div>
                    <div className="bk-file__desc">Файл можно добавить перетаскиванием</div>
                </div>
                {!!img && <><div className="bk-file__preview" style={{backgroundImage: 'url('+img+')'}}/></>}
                {
                    isDragActive &&
                    <div className="bk-area-file__placeholder">
                        <div className="bk-area-file__pl">Отпусти файл, чтобы добавить</div>
                    </div>
                }
            </div>
            {!!img && <div style={{marginTop: 10, textAlign: 'left'}}>
                <Button text={"Заменить"}/>
            </div>}
        </div>
    )
}

export default FileZone;