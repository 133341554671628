import React, {useEffect, useRef, useState} from "react";
import './index.css';

const Input = ({name, type, value, setValue, style, placeholder}) => {

    const ref = useRef();
    const [active, setActive] = useState(false);
    const [focus, setFocus] = useState(false);

    const onChange = (e) => {
        if(setValue){
            setValue(e.target.value);
        }
    }

    const onFocus = () => {
        ref.current.focus();
    }

    useEffect(() => {
        if(value){
            setActive(true);
        }else{
            setActive(focus);
        }
    }, [value, focus]);

    return(<div className="input" style={style} onClick={onFocus}>
        {placeholder && <label className={active ? 'label-hide' : ''}>{placeholder}</label>}
        <input name={name} type={type} value={value} onChange={onChange} ref={ref}
               onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}/>
    </div>)
}

export default Input;