import {
    ACTION_CURRENT_ARTICLES,
    ACTION_CURRENT_CHAPTERS,
    ACTION_IS_TWO_HEADER,
    ACTION_SET_CHAPTERS_MENU
} from "../../consts";

let start = {
    currentChaptersState: {},
    currentArticle: {},
    isTwoHead: false,
    currentChaptersMenu: {}
}

export function chapterReducer(current = start, action){
    switch (action.type){
        case ACTION_CURRENT_CHAPTERS: {
            return {
                ...current,
                currentChaptersState: action.payload
            }
        }
        case ACTION_IS_TWO_HEADER: {
            return {
                ...current,
                isTwoHead: action.payload
            }
        }
        case ACTION_CURRENT_ARTICLES: {
            return {
                ...current,
                currentArticle: action.payload
            }
        }
        case ACTION_SET_CHAPTERS_MENU: {
            return {
                ...current,
                currentChaptersMenu: action.payload
            }
        }
        default: return current
    }
}