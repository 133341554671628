import React from "react"
import './index.css'
import IconAdd from "../../Icons/Add";
import Text from "../Text";

const AddItem = ({item}) => {

    return(<div className="bk-area-add-item" onClick={item.action} style={!item.isBottomLine ? {border: 'none'} : {}}>
        <div className="bk-add-item__flex">
            <IconAdd/>
            <Text text={item.text} style={{marginLeft: 8}}/>
        </div>
    </div>)
}

export default AddItem