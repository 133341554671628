import {ACTION_UPDATE_MODAL} from "../../consts";

const start = {
    modal: {
        show: false,
        title: null,
        type: 'Search' | 'Delete' | 'Info',
        body: null,
        anchor: 'Top' | 'Center',
        params: {}
    }
}

export function appReducer (current = start, action) {
    switch (action.type){
        case ACTION_UPDATE_MODAL:{
            return {
                ...current,
                modal: {...current.modal, ...action.payload}
            }
        }
        default: return current
    }
}