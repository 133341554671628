import React from "react"
import './index.css'

const Button = ({text, action, style, mode='primary'}) => {

    return(<button onClickCapture={action} className={"bk-button"+(' bk-button__'+mode)} style={style}>
        {text}
    </button>)
}

export default Button