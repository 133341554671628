import React from "react";
import {useDispatch, useSelector} from "react-redux";
import DeleteModal from "./DeleteModal";
import './index.css';
import {updateModal} from "../../store/actions/appAction";

const Modal = ({}) => {

    const modal = useSelector(state => state.app.modal);
    const dispatch = useDispatch();

    const onCancel = (e, updateChapters=false) => {
        e.stopPropagation();
        if(!updateChapters){
            dispatch(updateModal({show: false}));
        }
    }

    return(<>{ modal.show && <div className="bk-modal-main" onClick={(e) => onCancel(e, false)}>
            <div className="bk-modal-main__content" onClickCapture={(e) => e.nativeEvent.stopImmediatePropagation()}>
                {
                    modal.type === 'Delete' ? <DeleteModal data={modal} onCancel={onCancel}/> : null
                }
            </div>
    </div>}</>);
}

export default Modal;