import React from "react"
import './index.css'

const Text = ({text, style={}}) => {

    return(<div className="bk-area-text" style={style}>
        {text}
    </div>)
}

export default Text