import React, {useRef} from "react"
import './index.css'

const TitleArea = ({val, name, onChanged, disabled=false}) => {
    const ref = useRef()

    const onChange = (e) => {
        ref.current.style.height = "5px";
        ref.current.style.height = (ref.current.scrollHeight)+"px";
        onChanged(e)
        console.log(e.target.value)
    }

    return(<div className="bk-area-title">
        <textarea value={val} name={name} onChange={onChange} ref={ref} disabled={disabled} placeholder="Название статьи"/>
    </div>)
}

export default TitleArea