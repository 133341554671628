import React, {useEffect} from 'react'
import './menu.css'
import ChapterItem from "./ChapterItem";
import ArticleItem from "./ArticleItem";
import ProfileItem from "./ProfileItem";
import HeadItem from "./HeadItem";
import HeadReturnItem from "./HeadReturnItem";
import {useDispatch} from "react-redux";
import {setTwoHeader} from "../../store/actions/chapterAction";
import AddItem from "./AddItem";
import SubMenu from "./SubMenu";

const Menu = ({items}) => {
    const dispatch = useDispatch()

    useEffect(() => {

    }, [items])

    return(<div className="bk-area-menu">
        {
                items.map((val, idx) => {
                    if (val.type === 'chapter') {
                        return (<ChapterItem item={val} key={idx}/>)
                    } else if (val.type === 'article') {
                        return (<ArticleItem item={val} key={idx}/>)
                    } else if (val.type === 'user') {
                        return (<ProfileItem user={val} key={idx}/>)
                    } else if (val.type === 'head') {
                        dispatch(setTwoHeader(false))
                        return (<HeadItem key={idx}/>)
                    } else if (val.type === 'headline') {
                        dispatch(setTwoHeader(true))
                        return (<HeadReturnItem item={val} key={idx}/>)
                    }else if(val.type === 'add'){
                        return (<AddItem item={val} key={idx}/>)
                    }else if(val.type === 'submenu'){
                        return (<SubMenu items={val.items} key={idx}/>)
                    } else {
                        return null
                    }
                })
        }
    </div>)
}

export default Menu