import React, {useEffect} from "react";
import Button from "../../Button";
import './index.css';
import {useCookies} from "react-cookie";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {deleteChapterOrArticle} from "../../../store/actions/appAction";
import {setCurrentArticle} from "../../../store/actions/chapterAction";

const DeleteModal = ({data, onCancel}) => {
    const [cookies, setCookie] = useCookies(['token', 'session_guid'])
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onDelete = () => {
        console.log(data, data.params.articleId, data.params.id);
        dispatch(deleteChapterOrArticle(data.params.id, data.params.chapterId, () => {
            if(parseInt(data.params.articleId) === data.params.id){
                if(data.params.chapterId){
                    navigate('/chapter/'+data.params.chapterId)
                }else{
                    navigate('/')
                }
                dispatch(setCurrentArticle({}));
            }
        }, cookies, setCookie));
    }

    useEffect(() => {
        console.log(data.params.chapterId, data.params.articleId);
    }, []);

    return(<div className="bk-modal__area">
        <div className="bk-modal__area-title">{data.title}</div>
        <div className="bk-modal__area-desc">{data.body}</div>
        <div className="bk-modal__area-button">
            <Button text={'Отмена'} action={(e) => onCancel(e, false)} style={{marginRight: 12}}/>
            <Button text={'Удалить'} mode={'delete'} action={onDelete}/>
        </div>
    </div>);
};

export default DeleteModal;